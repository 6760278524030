import React, { useState } from 'react';
import './App.css';
import { Routes, Route, useNavigate } from "react-router-dom";
import {
  ToolOutlined,
  FileTextOutlined,
  TeamOutlined,
  HomeOutlined,
  InfoCircleOutlined,
  SolutionOutlined,
} from '@ant-design/icons';
import { Layout, Menu, Input } from 'antd';
import { Home, About, People, AdminGlobal } from './pages';
import People3 from "./assets/people/3.jpg"
import logo from "./logo-white.png";
const { Header, Content, Footer, Sider } = Layout;
const { Search } = Input;

function getItem(label, key, icon, children) {
  return {
    key,
    children,
    label,
    icon,
  };
}
const paths = {
  "menu-home": "/",
  "menu-about": "/about",
  "menu-people": "/people",
  "admin-gl": "/services/admin/global",
};

const items = [
  getItem('Home', 'menu-home', <HomeOutlined style={{ fontSize: 20 }} />),
  getItem('About', 'menu-about', <InfoCircleOutlined style={{ fontSize: 20 }} />),
  getItem('Services', 'services', <ToolOutlined style={{ fontSize: 20 }} />, [
    getItem('Admin', 'admin', null, [
      getItem('Global', 'admin-gl', null),
      getItem('Australia', 'admin-au', null),
      getItem('China', 'admin-cn', null),
      getItem('India', 'admin-in', null),
      getItem('East Asia', 'admin-ea', null),
    ]),
    getItem('IT', 'it', null),
    getItem('Finance', '5', null, [
      getItem('Global', 'finance-gl', null),
      getItem('Australia', 'finance-au', null),
      getItem('China', 'finance-cn', null),
      getItem('India', 'finance-in', null),
      getItem('East Asia', 'finance-ea', null),
      getItem('UK', 'finance-uk', null),
      getItem('USA', 'finance-us', null),
    ], null),
    getItem('HR', 'hr', null, [
      getItem('Global', 'hr-gl', null),
      getItem('Australia', 'hr-au', null),
      getItem('China', 'hr-cn', null),
      getItem('India', 'hr-in', null),
      getItem('East Asia', 'hr-ea', null),
      getItem('UK', 'hr-uk', null),
      getItem('USA', 'hr-us', null),
    ]),
    getItem('Legal', 'legal', null),
    getItem('Business Solutions', 'bs', null),
    getItem('Marketing Solutions', 'ms', null),
  ]),
  getItem('SOP', 'sop', <FileTextOutlined style={{ fontSize: 20 }} />),
  getItem('People', 'menu-people', <TeamOutlined style={{ fontSize: 20 }} />),
  getItem('Careers', 'careers', <SolutionOutlined style={{ fontSize: 20 }} />),
];


function App() {
  const [collapsed, setCollapsed] = useState(true);
  const navigate = useNavigate();
  const onSearch = (value) => console.log(value);
  return (
    <div className="App">
    <Layout
    style={{
      minHeight: '100vh',
    }}
  >
    <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
      {/* <div className="logo" />
       */}
      <div className="logo" style={{ justifyContent: collapsed ? "center" : "flex-start" }}>
        <img src={logo} />
        {!collapsed &&
        <div>
          <span>George</span>
          <span>Clinical</span>
        </div>
        }
      </div>
      <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" items={items}
        onClick={(e) => {
          if(paths[e.key]) {
            navigate(paths[e.key]);
          }
        }} />
    </Sider>
    <Layout className="site-layout">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="people" element={<People />} />
        <Route path="services/admin/global" element={<AdminGlobal />} />
      </Routes>
      <Footer
        style={{
          textAlign: 'center',
          color: "#b0b0b6",
        }}
      >
        © 2022 George Clinical
      </Footer>
    </Layout>
  </Layout>

      {/* <Layout className="layout">
        <Header>
          <div className="logo">
            <img src={logo} />
          </div>
          <Row align='middle' style={{ flex: 1, justifyContent: "flex-end" }}>
            <Menu theme="light" defaultSelectedKeys={['1']} mode="horizontal" items={items}
              onClick={(e) => {
                if(paths[e.key]) {
                  navigate(paths[e.key]);
                }
              }} />
              <Search placeholder="Search.." allowClear onSearch={onSearch} style={{ width: 200 }} />
              <Avatar size={40} src={People3} style={{ marginLeft: 12 }} />
          </Row>
        </Header>
        <Content
          style={{
            padding: '16px 48px',
          }}
        >
          <Breadcrumb
            style={{
              margin: '16px 0',
            }}
          >
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>List</Breadcrumb.Item>
            <Breadcrumb.Item>App</Breadcrumb.Item>
          </Breadcrumb> 
          <div className="site-layout-content">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="about" element={<About />} />
              <Route path="people" element={<People />} />
            </Routes>
          </div>
        </Content>
        <Footer
          style={{
            textAlign: 'center',
            color: "#aaa"
          }}
        >
          © 2018 George Clinical
        </Footer>
      </Layout> */}
    </div>
  );
}

export default App;
