import React from "react";
import PropTypes from "prop-types";
import "./gc-node.css";

const propTypes = {
  nodeData: PropTypes.object.isRequired
};

const MyNode = ({ nodeData }) => {

  return (
    <div>
      <div className="designation">{nodeData.designation}</div>
      <div className="fullname">{nodeData.name}</div>
    </div>
  );
};

MyNode.propTypes = propTypes;

export default MyNode;