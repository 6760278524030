import React from "react";
import { Card, Input, Layout, Row, Avatar, Tabs, Menu, Breadcrumb, Col } from "antd";
import Logo from "../../../../logo.png";
import {
  CheckCircleFilled,
  CloseCircleFilled
} from '@ant-design/icons';
import People3 from "../../../../assets/people/3.jpg"
import Tile1 from "../../../../assets/services/admin/global/tile1.png"
import Tile2 from "../../../../assets/services/admin/global/tile2.png"
import Tile3 from "../../../../assets/services/admin/global/tile3.png"
import "../../style.scss"
const { Search } = Input;
const { Header, Content } = Layout;
const { TabPane } = Tabs;

const AdminGlobal = () => {
  const menu = (
    <Menu
      items={[
        {
          key: '1',
          label: (
            <a href="#">
              Global
            </a>
          ),
        },
        {
          key: '2',
          label: (
            <a href="#">
              Australia
            </a>
          ),
        },
        {
          key: '3',
          label: (
            <a href="#">
              China
            </a>
          ),
        },
        {
          key: '4',
          label: (
            <a href="#">
              India
            </a>
          ),
        },
        {
          key: '5',
          label: (
            <a href="#">
              East Asia
            </a>
          ),
        },
      ]}
    />
  );

  return (
    <div className="page-services elem-page">
      <Header
        className="site-layout-background"
        style={{
          padding: 0,
        }}
        title="Dashobard"
      >
        <Row justify="space-between" style={{ width: "100%" }}>
          {/* <h1 style={{ fontSize: 24, fontWeight: 600, marginBottom: 0 }}>About</h1> */}

          <Breadcrumb>
            <Breadcrumb.Item>Services</Breadcrumb.Item>
            <Breadcrumb.Item overlay={menu}>
              <a href="">Admin</a>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Global</Breadcrumb.Item>
          </Breadcrumb>

          <Row direction="horizontal" align="middle">
            <Search placeholder="Search.." allowClear onSearch={() => {}} style={{ width: 200 }} />
            <Avatar size={40} src={People3} style={{ marginLeft: 12 }} />
          </Row>
        </Row>
      </Header>
      
      <Content
        style={{
          margin: '0 16px',
        }}
      >
        <div className="site-layout-background">
          <Card>
            <Tabs defaultActiveKey="1">
              <TabPane tab="General Info" key="1">
                <div style={{ textAlign: "center", margin: "20px 0 30px" }}>
                  <Row gutter={16}>
                    <Col span={6}>
                      <a
                        href="https://sp.georgeinstitute.org/IT/Pages/ITServices.aspx"
                        target="_blank"
                        className="slide-item"
                        style={{
                          display: "block",
                          marginBottom: 8,
                          backgroundImage: `url(${Tile1})`,
                        }}>
                        <div className="slide-caption">
                          <h3>Video conferencing</h3>
                        </div>
                      </a>
                    </Col>
                    <Col span={6}>
                      <a
                        href="https://sp.georgeinstitute.org/IT/Pages/ITServices.aspx"
                        target="_blank"
                        className="slide-item"
                        style={{
                          display: "block",
                          marginBottom: 8,
                          backgroundImage: `url(${Tile2})`,
                        }}>
                        <div className="slide-caption">
                          <h3>Skype User Instructions​</h3>
                        </div>
                      </a>
                    </Col>
                    <Col span={6}>
                      <a
                        href="https://sp.georgeinstitute.org/Admin/AdminAU/Pages/Printer.aspx"
                        target="_blank"
                        className="slide-item"
                        style={{
                          display: "block",
                          marginBottom: 8,
                          backgroundImage: `url(${Tile3})`,
                        }}>
                        <div className="slide-caption">
                          <h3>Printers</h3>
                        </div>
                      </a>
                    </Col>
                  </Row>
                </div>
              </TabPane>
              <TabPane tab="Travel" key="2">
                <div style={{ textAlign: "center", margin: "20px 0 30px" }}>
                  Travel info
                </div>
              </TabPane>
            </Tabs>
          </Card>
      </div>
      </Content>
    </div>
  );
};

export default AdminGlobal;