import React, { useState } from "react";
import { Row, Tabs, Layout, Input, Avatar } from "antd";
import {
  HomeOutlined,
  ApartmentOutlined,
  ContactsOutlined,
} from '@ant-design/icons';

import People3 from "../../assets/people/3.jpg"
import PeopleDir from "./PeopleDir";
import Overview from "./Overview";
import OrgTree from "./OrgTree";

const { Header, Content } = Layout;
const { Search } = Input;
const { TabPane } = Tabs;

const People = () => {
  return (
    <div id="page-people" className="elem-page">
      <Header
        className="site-layout-background"
        style={{
          padding: 0,
        }}
        title="Dashobard"
      >
        <Row justify="space-between" style={{ width: "100%" }}>
          <h1 style={{ fontSize: 24, fontWeight: 600, marginBottom: 0 }}>People</h1>

          <Row direction="horizontal" align="middle">
            <Search placeholder="Search.." allowClear onSearch={() => {}} style={{ width: 200 }} />
            <Avatar size={40} src={People3} style={{ marginLeft: 12 }} />
          </Row>
        </Row>
      </Header>
      
      <Content
        style={{
          margin: '0 16px',
        }}
      >
        <div className="site-layout-background">
        <Tabs defaultActiveKey="1">
          <TabPane
            tab={
              <span>
                <HomeOutlined />
                Overview
              </span>
            }
            key="1"
          >
          <Overview />
          </TabPane>
          <TabPane
            tab={
              <span>
                <ContactsOutlined />
                People Directory
              </span>
            }
            key="2"
          >
            <PeopleDir />
          </TabPane>
          <TabPane
            tab={
              <span>
                <ApartmentOutlined />
                Organizational Tree
              </span>
            }
            key="3"
          >
            <OrgTree />
          </TabPane>
        </Tabs>
      </div>
      </Content>
    </div>
  );
};

export default People;