import React from "react";
import { Card, Input, Tabs, Layout, Row, Avatar, Table } from "antd";
import Logo from "../../logo.png";
import {
  CheckCircleFilled,
  CloseCircleFilled
} from '@ant-design/icons';
import People3 from "../../assets/people/3.jpg"
const { TabPane } = Tabs;
const { Search } = Input;
const { Header, Content } = Layout;

const onChange = (key) => {
  console.log(key);
};

const About = () => {
  const locations = [
    {
      location: "Australia",
      Office: "Sydney",
      pm: true,
      co: true,
      dm: true,
      mss: true,
      emd: true,
      qm: true,
      rs: true,
      st: true,
      he: true,
    },
    {
      location: "New Zealand",
      Office: "Auckland",
      pm: true,
      co: true,
      dm: false,
      mss: false,
      emd: false,
      qm: false,
      rs: false,
      st: false,
      he: false,
    },
    {
      location: "China",
      Office: "Beijing",
      pm: true,
      co: true,
      dm: true,
      mss: true,
      emd: false,
      qm: true,
      rs: true,
      st: true,
      he: true,
    },
    {
      location: "",
      Office: "Shanghai",
      pm: false,
      co: true,
      dm: false,
      mss: false,
      emd: false,
      qm: false,
      rs: false,
      st: false,
      he: false,
    },
    {
      location: "",
      Office: "Chengdu",
      pm: false,
      co: true,
      dm: false,
      mss: false,
      emd: false,
      qm: false,
      rs: false,
      st: false,
      he: false,
    },
    {
      location: "",
      Office: "Harbin",
      pm: true,
      co: true,
      dm: false,
      mss: false,
      emd: false,
      qm: false,
      rs: false,
      st: false,
      he: false,
    },
    {
      location: "India",
      Office: "Bangalore",
      pm: true,
      co: true,
      dm: true,
      mss: true,
      emd: true,
      qm: true,
      rs: true,
      st: true,
      he: false,
    },
    {
      location: "Czech Republic",
      Office: "Prague",
      pm: true,
      co: true,
      dm: false,
      mss: false,
      emd: false,
      qm: false,
      rs: false,
      st: false,
      he: false,
    },
    {
      location: "Hong Kong",
      Office: "Hong Kong",
      pm: true,
      co: true,
      dm: false,
      mss: false,
      emd: false,
      qm: true,
      rs: true,
      st: false,
      he: false,
    },
    {
      location: "Japan",
      Office: "Tokyo",
      pm: true,
      co: true,
      dm: false,
      mss: false,
      emd: false,
      qm: false,
      rs: false,
      st: false,
      he: false,
    },
    {
      location: "Korea",
      Office: "Seoul",
      pm: true,
      co: true,
      dm: false,
      mss: false,
      emd: false,
      qm: false,
      rs: false,
      st: false,
      he: false,
    },
    {
      location: "Malaysia, Singapore",
      Office: "Kuala Lumpur",
      pm: true,
      co: true,
      dm: false,
      mss: false,
      emd: false,
      qm: false,
      rs: true,
      st: false,
      he: false,
    },
    {
      location: "Taiwan",
      Office: "Taipei",
      pm: true,
      co: true,
      dm: false,
      mss: false,
      emd: false,
      qm: false,
      rs: true,
      st: false,
      he: false,
    },
    {
      location: "United Kingdom",
      Office: "London",
      pm: true,
      co: true,
      dm: false,
      mss: false,
      emd: false,
      qm: false,
      rs: true,
      st: false,
      he: false,
    },
    {
      location: "USA",
      Office: "Memphis, Kansas City",
      pm: true,
      co: true,
      dm: true,
      mss: true,
      emd: true,
      qm: true,
      rs: true,
      st: true,
      he: true,
    },
  ];

  const columns = [
    {
      title: "location",
      dataIndex: "location",
      render: (cell) => <span style={{ fontWeight :"500" }}>{cell}</span>
    },
    {
      title: "Offices",
      dataIndex: "Office",
    },
    {
      title: "Project Management",
      dataIndex: "pm",
      render: (cell) => cell ? <CheckCircleFilled style={{ color: "rgb(135, 198, 160)", fontSize: 20, }} /> : <CloseCircleFilled style={{ color: "#e2e2e8", fontSize: 20 }} />,
    },
    {
      title: "Clinical Operations",
      dataIndex: "co",
      render: (cell) => cell ? <CheckCircleFilled style={{ color: "rgb(135, 198, 160)", fontSize: 20, }} /> : <CloseCircleFilled style={{ color: "#e2e2e8", fontSize: 20 }} />,
    },
    {
      title: "Data Management",
      dataIndex: "dm",
      render: (cell) => cell ? <CheckCircleFilled style={{ color: "rgb(135, 198, 160)", fontSize: 20, }} /> : <CloseCircleFilled style={{ color: "#e2e2e8", fontSize: 20 }} />,
    },
    {
      title: "Medical & Safety Services",
      dataIndex: "mss",
      render: (cell) => cell ? <CheckCircleFilled style={{ color: "rgb(135, 198, 160)", fontSize: 20, }} /> : <CloseCircleFilled style={{ color: "#e2e2e8", fontSize: 20 }} />,
    },
    {
      title: "Endpoint Management & DSMB",
      dataIndex: "emd",
      render: (cell) => cell ? <CheckCircleFilled style={{ color: "rgb(135, 198, 160)", fontSize: 20, }} /> : <CloseCircleFilled style={{ color: "#e2e2e8", fontSize: 20 }} />,
    },
    {
      title: "Quality Management",
      dataIndex: "qm",
      render: (cell) => cell ? <CheckCircleFilled style={{ color: "rgb(135, 198, 160)", fontSize: 20, }} /> : <CloseCircleFilled style={{ color: "#e2e2e8", fontSize: 20 }} />,
    },
    {
      title: "Regulatory Services",
      dataIndex: "rs",
      render: (cell) => cell ? <CheckCircleFilled style={{ color: "rgb(135, 198, 160)", fontSize: 20, }} /> : <CloseCircleFilled style={{ color: "#e2e2e8", fontSize: 20 }} />,
    },
    {
      title: "Statistics",
      dataIndex: "st",
      render: (cell) => cell ? <CheckCircleFilled style={{ color: "rgb(135, 198, 160)", fontSize: 20, }} /> : <CloseCircleFilled style={{ color: "#e2e2e8", fontSize: 20 }} />,
    },
    {
      title: "Health Economics",
      dataIndex: "he",
      render: (cell) => cell ? <CheckCircleFilled style={{ color: "rgb(135, 198, 160)", fontSize: 20, }} /> : <CloseCircleFilled style={{ color: "#e2e2e8", fontSize: 20 }} />,
    },
  ];

  return (
    <div id="page-about" className="elem-page">
      <Header
        className="site-layout-background"
        style={{
          padding: 0,
        }}
        title="Dashobard"
      >
        <Row justify="space-between" style={{ width: "100%" }}>
          <h1 style={{ fontSize: 24, fontWeight: 600, marginBottom: 0 }}>About</h1>

          <Row direction="horizontal" align="middle">
            <Search placeholder="Search.." allowClear onSearch={() => {}} style={{ width: 200 }} />
            <Avatar size={40} src={People3} style={{ marginLeft: 12 }} />
          </Row>
        </Row>
      </Header>
      
      <Content
        style={{
          margin: '0 16px',
        }}
      >
        <div className="site-layout-background">
          <Card>
            <Tabs defaultActiveKey="1" onChange={onChange}>
              <TabPane tab="About Us" key="1">
                <div style={{ textAlign: "center", margin: "20px 0 30px" }}>
                  <img src={Logo} />
                </div>
                <p>George Clinical is a leading global clinical research organization founded in Asia-Pacific driven by scientific expertise and operational excellence. With over 20 years of experience and more than 400 people managing 39 geographical locations throughout the Asia-Pacific region, USA, and Europe.</p>
                <p>George Clinical provides the full range of clinical trial services to biopharmaceutical, medical device, and diagnostic customers, for all trial phases, registration, and post-marketing trials.</p>
              </TabPane>
              <TabPane tab="Locations" key="2">
                <div style={{ textAlign: "center", margin: "20px 0 30px" }}>
                  <img src={require("../../assets/locations.png")} style={{ width: "100%" }} />
                </div>
              </TabPane>
              <TabPane tab="Service Offerings" key="3">
                <Table
                  dataSource={locations}
                  columns={columns}
                />
              </TabPane>
              <TabPane tab="Functional Structure" key="4">
                <div style={{ textAlign: "center", margin: "20px 0 30px" }}>
                  <img src={require("../../assets/functional.png")} style={{ width: "96%" }} />
                </div>
              </TabPane>
              <TabPane tab="Contact Us" key="5">
                

              <p><a href="https://www.georgeclinical.com/contact-us" target="_blank">Office location</a></p>

              <p><a href="https://www.georgeclinical.com/group/management-team">George Clinical Management</a></p>

              <h3>General Inquiries</h3>
              <p><a href="mailto:customerservice@georgeclinical.com">customerservice@georgeclinical.com</a></p>

              <h3 style={{ marginTop: 50 }}>Australia</h3>
              
              <h4>Headquarters</h4>

              <p>Level 5, 1 King Street, Newtown<br/>
              NSW 2042 Australia</p>
              <p>Phone: +61 2 8052 4300</p>
              <p>Fax: +61 2 8052 4301</p>

              <h4>Postal Address</h4>
              <p>PO Box M201, Missenden Rd<br/>
              NSW 2050 Australia</p>


              </TabPane>
            </Tabs>
          </Card>
      </div>
      </Content>
    </div>
    // <div id="page-about">
    //   <h1>About</h1>
    //   <Card>
    //   </Card>
    // </div>
  );
};

export default About;