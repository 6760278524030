import React, { useState } from "react";
import { Card, Table, Input, Avatar } from "antd";

import People1 from "../../assets/people/1.jpg"
import People2 from "../../assets/people/2.jpg"
import People3 from "../../assets/people/3.jpg"
import People4 from "../../assets/people/4.jpg"
import People5 from "../../assets/people/5.jpg"
import People6 from "../../assets/people/6.jpg"
import People7 from "../../assets/people/7.jpg"
import People8 from "../../assets/people/8.jpg"
import People9 from "../../assets/people/9.jpg"
import People10 from "../../assets/people/10.jpg"

const PeopleDir = () => {
  const [queryName, setQueryName] = useState("");
  const [queryDept, setQueryDept] = useState("");
  const [queryPhone, setQueryPhone] = useState("");
  const [queryEmail, setQueryEmail] = useState("");
  const [queryLocation, setQueryLocation] = useState("");
  const dataSet = [{
    name: "Robert Fox",
    department: "Front Office",
    phone: "+7 (903) 880-93-38",
    email: "binhan628@gmail.com",
    location: "Dominica",
    avatar: People1,
    designation: "Content Writer",
    dob: "11/08/1984",
    dateJoining: "13/12/2020",
  },
  {
    name: "Arlene McCoy",
    department: "Food & Beverage",
    phone: "+7 (903) 679-96-15",
    email: "nvt.isst.nute@gmail.com",
    location: "Djibouti",
    avatar: People2,
    designation: "Architect",
    dob: "22/08/1969",
    dateJoining: "12/06/1999",
  },
  {
    name: "Devon Lane",
    department: "Procurement / Purchasing",
    phone: "+7 (903) 880-91-85",
    email: "vuhaithuongnute@gmail.com",
    location: "Congo, Republic of the",
    avatar: People3,
    designation: "Software Engineer",
    dob: "27/11/1987",
    dateJoining: "27/11/2001",
  },
  {
    name: "Courtney Henry",
    department: "Security",
    phone: "+7 (903) 679-96-15",
    email: "tienlapspktnd@gmail.com",
    location: "Liechtenstein",
    avatar: People4,
    designation: "Chief Operating Officer",
    dob: "07/05/1976",
    dateJoining: "07/05/2018",
  },
  {
    name: "Savannah Nguyen",
    department: "Uniformed Services",
    phone: "+7 (903) 941-02-27",
    email: "tranthuy.nute@gmail.com",
    location: "Guatemala",
    avatar: People5,
    designation: "Manager",
    dob: "03/09/1963",
    dateJoining: "03/09/2011",
  },
  {
    name: "Darlene Robertson",
    department: "Risk Management",
    phone: "+7 (903) 840-31-53",
    email: "manhhachkt08@gmail.com",
    location: "Burundi",
    avatar: People6,
    designation: "Chief Executive Officer",
    dob: "02/07/1971",
    dateJoining: "02/07/2022",
  },
  {
    name: "Marvin McKinney",
    department: "Payroll",
    phone: "+7 (903) 880-93-38",
    email: "trungkienspktnd@gamail.com",
    location: "Bangladesh",
    avatar: People7,
    designation: "Delivery Head",
    dob: "06/01/1976",
    dateJoining: "06/01/2021",
  },
  {
    name: "Cody Fisher",
    department: "Human Resources",
    phone: "+7 (903) 134-55-26",
    email: "danghoang87hl@gmail.com",
    location: "Benin",
    avatar: People8,
    designation: "Content Writer",
    dob: "19/08/1974",
    dateJoining: "19/08/2021",
  },
  {
    name: "Jacob Jones",
    department: "Maintenance",
    phone: "+7 (903) 880-91-85",
    email: "ckctm12@gmail.com",
    location: "Egypt",
    avatar: People9,
    designation: "President",
    dob: "28/03/1968",
    dateJoining: "28/03/1998",
  },
  {
    name: "Dianne Russell",
    department: "Housekeeping",
    phone: "+7 (903) 880-91-85",
    email: "thuhang.nute@gmail.com",
    location: "Haiti",
    avatar: People10,
    designation: "Vice President",
    dob: "12/08/1994",
    dateJoining: "12/08/2002",
  }];

  const FilterByNameInput = (
    <div>
      <h4>Name</h4>
      <Input
        placeholder="Search.."
        value={queryName}
        onChange={e => {
          setQueryName(e.target.value);
        }}
      />
    </div>
  );
  const FilterByDeptInput = (
    <div>
      <h4>Department</h4>
      <Input
        placeholder="Search.."
        value={queryDept}
        onChange={e => {
          setQueryDept(e.target.value);
        }}
      />
    </div>
  );
  const FilterByPhoneInput = (
    <div>
      <h4>Phone</h4>
      <Input
        placeholder="Search.."
        value={queryPhone}
        onChange={e => {
          setQueryPhone(e.target.value);
        }}
      />
    </div>
  );
  const FilterByEmailInput = (
    <div>
      <h4>Email</h4>
      <Input
        placeholder="Search.."
        value={queryEmail}
        onChange={e => {
          setQueryEmail(e.target.value);
        }}
      />
    </div>
  );
  const FilterByLocationInput = (
    <div>
      <h4>Location</h4>
      <Input
        placeholder="Search.."
        value={queryLocation}
        onChange={e => {
          setQueryLocation(e.target.value);
        }}
      />
    </div>
  );

  const columns = [
    {
      title: "",
      dataIndex: "avatar",
      render: (cell) => <Avatar src={cell} size={40} />
    },
    {
      title: FilterByNameInput,
      dataIndex: "name",
      filtered: true,
      filteredValue: queryName.trim() === "" ? [] : [queryName],
      onFilter: (value, filters) => filters.name.toLowerCase().includes(value.toLowerCase()),
      render: (cell) => <span style={{ fontWeight :"500" }}>{cell}</span>
    },
    {
      title: FilterByDeptInput,
      dataIndex: "department",
      filtered: true,
      filteredValue: queryDept.trim() === "" ? [] : [queryDept],
      onFilter: (value, filters) => filters.department.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: FilterByPhoneInput,
      dataIndex: "phone",
      filtered: true,
      filteredValue: queryPhone.trim() === "" ? [] : [queryPhone],
      onFilter: (value, filters) => filters.phone.toLowerCase().includes(value.toLowerCase()),
      render: (cell) => <a href={`tel:${cell}`}>{cell}</a>
    },
    {
      title: FilterByEmailInput,
      dataIndex: "email",
      filtered: true,
      filteredValue: queryEmail.trim() === "" ? [] : [queryEmail],
      onFilter: (value, filters) => filters.email.toLowerCase().includes(value.toLowerCase()),
      render: (cell) => <a href={`mailto:${cell}`}>{cell}</a>
    },
    {
      title: FilterByLocationInput,
      dataIndex: "location",
      filtered: true,
      filteredValue: queryLocation.trim() === "" ? [] : [queryLocation],
      onFilter: (value, filters) => filters.location.toLowerCase().includes(value.toLowerCase()),
    },
  ];

  return (
    <Card>
      <Table
        dataSource={dataSet}
        columns={columns}
        rowKey="name"
      />
    </Card>
  );
};

export default PeopleDir;