import React from "react";
import { Row, Col, Space, Layout, List, Input, Avatar, Carousel, Button, Card } from "antd";
import {
  BellOutlined,
  FileTextOutlined,
  TeamOutlined,
  DatabaseOutlined,
  MailOutlined,
  CustomerServiceOutlined,
  DesktopOutlined,
  KeyOutlined,
  LineChartOutlined,
  CommentOutlined,
  GlobalOutlined,
  RiseOutlined,
  TrophyOutlined,
  MedicineBoxOutlined,
  ScheduleOutlined,
  SmileOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import Clock from 'react-live-clock';
import "./style.scss";
// const contentStyle = {
//   height: '480px',
//   color: '#fff',
//   lineHeight: '480px',
//   textAlign: 'center',
//   background: '#364d79',
// };
import Slide1 from "../../assets/slide1.jpg"
import Slide2 from "../../assets/slide2.jpg"
import Slide3 from "../../assets/slide3.jpg"
import Ceo from "../../assets/ceo.jpeg"

import Contact1 from "../../assets/contacts/1.png"
import Contact2 from "../../assets/contacts/2.png"
import Contact3 from "../../assets/contacts/3.png"
import Contact4 from "../../assets/contacts/4.png"

import Blog1 from "../../assets/blog/1.png"
import Blog2 from "../../assets/blog/2.png"
import Blog3 from "../../assets/blog/3.png"
import Blog4 from "../../assets/blog/4.png"

import Social1 from "../../assets/social/1.jpg"
import Social2 from "../../assets/social/2.jpg"
import Social3 from "../../assets/social/3.jpg"
import Social4 from "../../assets/social/4.jpg"
import Social5 from "../../assets/social/5.jpg"
import Social6 from "../../assets/social/6.jpg"
import Social7 from "../../assets/social/7.jpg"
import Social8 from "../../assets/social/8.jpg"
import Social9 from "../../assets/social/9.jpg"
import Social10 from "../../assets/social/10.jpg"
import Social11 from "../../assets/social/11.jpg"
import Social12 from "../../assets/social/12.jpg"
import Social13 from "../../assets/social/13.jpg"
import Social14 from "../../assets/social/14.jpg"
import Social15 from "../../assets/social/15.jpg"
import Social16 from "../../assets/social/16.jpg"
import People3 from "../../assets/people/3.jpg"
const { Header, Content, Footer, Sider } = Layout;
const { Search } = Input;

const slides = [
  {
    title: "Welcome to the 54th edition of SOPs, Training and Quality Newsletter",
    desc: "Q News is a global communications tool delivering important announcements on George Clinical policies, procedures, training and quality that impact George Clinical.",
    url: "https://gclinical.sharepoint.com/sites/GCWiz/Resources/Documents/202206_Q_News_54_Jul2022_final.pdf",
    image: Slide1,
    cta: "Read Now",
  },
  {
    title: "IBM Highlights George Clinical Data Management Successes",
    desc: "IBM recently highlighted the success George Clinical has enjoyed over the past several years leveraging the firm as a data, analytics and technology partner.",
    url: "https://www.georgeclinical.com/resources/corporate-news/ibm-highlights-management",
    image: Slide2,
  },
  {
    title: "George Clinical Provides Innovative Solutions to Mitigate Risks, Manage Fragile Biological Samples and Improve Patient Experience",
    desc: "Processes in multicenter study investigating safety and efficacy of combination drugs in patients with locally advanced or metastatic solid tumors amended successfully.",
    url: "https://www.georgeclinical.com/case-studies/innovative-solutions",
    image: Slide3,
    cta: "View Case Study",
  }
];

const events = [
  {
    date: "15",
    month: "Aug",
    day: "Monday",
    name: "Follow up proposal",
  },
  {
    date: "31",
    month: "Aug",
    day: "Wednesday",
    name: "Meeting to talk about Ross contract. ",
  },
  {
    date: "08",
    month: "Sept",
    day: "Thursday",
    name: "Visit to discuss improvements",
  },
  {
    date: "05",
    month: "Oct",
    day: "Wednesday",
    name: "Monthly catch-up",
  },
];

const contacts = [
  {
    name: "Arlene McCoy",
    designation: "Marketing Coordinator",
    image: Contact1,
  },
  {
    name: "Brooklyn Simmons",
    designation: "Nursing Assistant",
    image: Contact2,
  },
  {
    name: "Darrell Steward",
    designation: "Medical Assistant",
    image: Contact3,
  },
  {
    name: "Marvin McKinney",
    designation: "President of Sales",
    image: Contact4,
  },
];

const blogPosts = [
  {
    title: "How to design a product that can grow itself 10x in year:",
    date: "21 Jun 2022",
    image: Blog1,
  },
  {
    title: "How to design a product that can grow itself 10x in year:",
    date: "12 Jun 2022",
    image: Blog2,
  },
  {
    title: "Understanding color theory: the color wheel and finding complementary colors",
    date: "25 May 2022",
    image: Blog3,
  },
  {
    title: "The More Important the Work, the More Important the Rest",
    date: "03 May 2022",
    image: Blog4,
  },
];


const links = [
  {
    url: "https://gclinical.sharepoint.com/sites/GCProject/Legal/CTRA",
    name: "CTRA Review",
    icon: <FileTextOutlined />,
    target: "_blank",
  },
  {
    url: "http://georgepeople.georgeinstitute.org/",
    name: "George People",
    icon: <TeamOutlined />,
    target: "_blank",
    title: "George People is not yet migrated. You are requested to right click and open this link in Incognito/Inprivtae mode and sign-in as mentioned below Sign-in : username@george.org.au Password: You use to login your laptop",
  },
  {
    url: "https://web.microsoftstream.com/group/43320385-d5dc-4375-b0ad-06c9d5d44e4c",
    name: "G​eorge TV​​​",
    icon: <DesktopOutlined />,
  },
  {
    url: "https://gclinical.sharepoint.com/sites/GCWiz/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FGCWiz%2FShared%20Documents%2FAccess%20Jedox%20%2D%20Migrated%20GC%20machine%2Epdf&amp;parent=%2Fsites%2FGCWiz%2FShared%20Documents",
    name: "Access Jedox - Migrated GC Machine",
    icon: <DatabaseOutlined />,
  },
  {
    url: "https://gclinical.sharepoint.com/sites/GCWiz/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FGCWiz%2FShared%20Documents%2FAccess%20Jedox%20%2D%20Non%2DMigrated%20GC%20machine%2Epdf&amp;parent=%2Fsites%2FGCWiz%2FShared%20Documents",
    name: "Access Jedox - Non-Migrated GC Machine",
    icon: <DatabaseOutlined />,
  },
  {
    url: "https://outlook.office.com/",
    name: "Webmail - GC",
    icon: <MailOutlined />,
    target: "_blank",
  },
  {
    url: "https://mail.tgi.cloud",
    name: "Webmail - TGI",
    icon: <MailOutlined />,
    target: "_blank",
  },
  {
    url: "https://it.georgeclinical.com/",
    name: "IT Ticketing System",
    icon: <CustomerServiceOutlined />,
    target: "_blank",
  },
  {
    url: "https://georgeclinicalservicedesk.freshservice.com/support/home",
    name: "Quality Ticketing System",
    icon: <CustomerServiceOutlined />,
    target: "_blank",
  },
  {
    url: "https://gcvault.georgeclinical.com",
    name: "Gclin Password Reset",
    icon: <KeyOutlined />,
    target: "_blank",
  },
  {
    url: "https://gc-force.my.salesforce.com/",
    name: "SalesForce",
    icon: <LineChartOutlined />,
    target: "_blank",
  },
  {
    url: "https://gclinical.sharepoint.com/sites/GCWiz/Marketing",
    name: "Marketing & Communications",
    icon: <CommentOutlined />,
    target: "_blank",
  },
  {
    url: "https://gclinical.sharepoint.com/sites/gcproject",
    name: "Sharepoint:Team sites",
    icon: <GlobalOutlined />,
    target: "_blank",
  },
  {
    url: "https://gclinical.sharepoint.com/sites/GCWiz/ps",
    name: "GC Business Solutions",
    icon: <RiseOutlined />,
    target: "_blank",
  },
  {
    url: "https://gclinical.sharepoint.com/sites/GCWiz/Resources/Pages/Trainings.aspx",
    name: "Training",
    icon: <TrophyOutlined />,
    target: "_blank",
  },
  {
    url: "https://gclinical.sharepoint.com/sites/gcproject/gcvendors",
    name: "GC Vendors",
    // icon: ,
    target: "_blank",
  },
  {
    url: "https://healthatworkdashboard.net.au/george-clinical",
    name: "Health@Work",
    icon: <MedicineBoxOutlined />,
    target: "_blank",
  },
  {
    url: "https://kstbookings.georgeinstitute.org/",
    name: "KST Bookings",
    icon: <ScheduleOutlined />,
    target: "_blank",
  },
  {
    url: "https://gclinical.sharepoint.com/sites/GCWiz/Lists/CB/Newform.aspx",
    name: "Compliment Box",
    icon: <SmileOutlined />,
    target: "_blank",
  },
];

const Home = () => {
  return (
    <div id="page-home" className="elem-page">

    <Header
      className="site-layout-background"
      style={{
        padding: 0,
      }}
      title="Dashobard"
    >
      <Row justify="space-between" style={{ width: "100%" }}>
        <h1 style={{ fontSize: 24, fontWeight: 600, marginBottom: 0 }}>Dashboard</h1>

        <Row direction="horizontal" align="middle">
          <Search placeholder="Search.." allowClear onSearch={() => {}} style={{ width: 200 }} />
          <Avatar size={40} src={People3} style={{ marginLeft: 12 }} />
        </Row>
      </Row>
    </Header>
    
    <Content
        style={{
          margin: '0 16px',
        }}
      >
        {/* <Breadcrumb
          style={{
            margin: '16px 0',
          }}
        >
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>About</Breadcrumb.Item>
        </Breadcrumb> */}
        <div className="site-layout-background">

      <Row gutter={16}>
        <Col span={16}>
          <Carousel autoplay autoplaySpeed={3000} dotPosition="bottom">
            { slides.map((s, sI) => (
              <div key={sI} className="slide-wrap">
                <div
                  style={{
                    height: 480,
                    backgroundImage: `url(${s.image})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover"
                  }}
                >
                  {/* <img src={s.image} /> */}
                  <div className="slide-caption">
                    <h3>{s.title}</h3>
                    <p>{s.desc}</p>
                    <a href={s.url || "#"} target="_blank">{s.cta || "Read more"}</a>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
          <Row gutter={16}>
            <Col span={12}>
              <Card>
                <Row justify="space-between">
                  <h3>Quick Contacts</h3>
                  <Button size="small">View All</Button>
                </Row>
                <List
                  itemLayout="horizontal"
                  dataSource={contacts}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={<Avatar size={72} src={item.image} />}
                        title={item.name}
                        description={item.designation}
                      />
                    </List.Item>
                  )}
                />
              </Card>
            </Col>
            <Col span={12}>
              <Card>
                <Row justify="space-between">
                  <h3>Blog Posts</h3>
                  <Button size="small">View All</Button>
                </Row>
                <List
                  itemLayout="horizontal"
                  dataSource={blogPosts}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={<Avatar size={72} src={item.image} />}
                        title={item.title.substring(0, 60) + (item.title.length > 60 ? ".." : "")}
                        description={item.date}
                      />
                    </List.Item>
                  )}
                />
              </Card>
            </Col>
          </Row>
          <Card className="social-wrap">
            <h3>Social Wall</h3>
            <Row gutter={8} style={{ marginBottom: 8 }}>
              <Col span={6}>
                <img src={Social1} />
              </Col>
              <Col span={6}>
                <img src={Social2} />
              </Col>
              <Col span={6}>
                <img src={Social3} />
              </Col>
              <Col span={6}>
                <img src={Social4} />
              </Col>
            </Row>
            <Row gutter={8} style={{ marginBottom: 8 }}>
              <Col span={6}>
                <img src={Social5} />
              </Col>
              <Col span={6}>
                <img src={Social6} />
              </Col>
              <Col span={6}>
                <img src={Social7} />
              </Col>
              <Col span={6}>
                <img src={Social8} />
              </Col>
            </Row>
            <Row gutter={8} style={{ marginBottom: 8 }}>
              <Col span={6}>
                <img src={Social9} />
              </Col>
              <Col span={6}>
                <img src={Social10} />
              </Col>
              <Col span={6}>
                <img src={Social11} />
              </Col>
              <Col span={6}>
                <img src={Social12} />
              </Col>
            </Row>
            <Row gutter={8} style={{ marginBottom: 8 }}>
              <Col span={6}>
                <img src={Social13} />
              </Col>
              <Col span={6}>
                <img src={Social14} />
              </Col>
              <Col span={6}>
                <img src={Social15} />
              </Col>
              <Col span={6}>
                <img src={Social16} />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Row justify="space-between">
              <h3>Announcements</h3>
              <Button size="small">View All</Button>
            </Row>
            <List
              itemLayout="horizontal"
              dataSource={[0,0,0]}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<Avatar icon={<BellOutlined />} />}
                    title={<a href="https://ant.design">Important announcement</a>}
                    description="Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint."
                  />
                </List.Item>
              )}
            />
          </Card>
          <Card>
            <h3>From CEO's Desk</h3>
            <div>
              <img src={Ceo} style={{ height: 96, float: "right" }} />
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Fugiat ut aut aperiam de quis, quam tempore non vitae maxime, commodi iusto nam, quisquam ab vel officia.</p>
              <p>Fugiat ut aut aperiam de quis, quam tempore non vitae maxime, commodi iusto nam, quisquam ab vel officia.</p>
            </div>
          </Card>
          <Card>
            <Row justify="space-between">
              <h3>Events</h3>
              <Button size="small">View All</Button>
            </Row>
            <List
              itemLayout="horizontal"
              dataSource={events}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={
                      <div className="holiday">
                        <h3>{item.date}</h3>
                        <p>{item.month}</p>
                      </div>
                    }
                    title={item.name}
                    description="Lorem ipsum dolor sit amet magna aliqua  ipsum dolor sit amet magna aliqua"
                  />
                </List.Item>
              )}
            />
          </Card>
          <div className="links-wrap">
            <h3>Useful Links</h3>
            <ul>
              {links.map((l, lI) => (
                <li key={lI}>
                  <LinkOutlined style={{ marginRight: 12 }} />
                  <a href={l.url} target={l.target || "_blank"}>{l.name}</a>
                </li>
              ))}
            </ul>
          </div>
        </Col>
      </Row>
        </div>
      </Content>
    </div>
  );
};

export default Home;