import React, { useState, useRef } from "react";
import { Card, Modal, Button, Space, Avatar, Input, Select } from "antd";
import OrganizationChart from "@dabeng/react-orgchart";
import GcNode from "./GcNode";
import {
  PhoneOutlined,
  MailOutlined,
} from '@ant-design/icons';
import People1 from "../../assets/people/7.jpg";
import "./orgtree.scss";
const { Option } = Select;

const OrgTree = () => {
  const orgchart = useRef();
  const [selected, setSelected] = useState(null);
  const [filename, setFilename] = useState("organization_chart");
  const [fileExtension, setFileExtension] = useState("pdf");
  const people = {
    id: 1,
    name: "Lloyd Weber",
    designation: "President",
    email: "vuhaithuongnute@gmail.com",
    phone: "(445) 474-9213",
    children: [
      {
        id: 2,
        name: "Kelvin Lucas",
        designation: "Program Manager",
        email: "thuhang.nute@gmail.com",
        phone: "(529) 394-5629",
        children: [
          {
            id: 3,
            name: "Ella Simon",
            designation: "QA Engineer",
            email: "trungkienspktnd@gamail.com",
            phone: "(537) 869-9604",
            children: [
              {
                id: 4,
                name: "Kelley Smith",
                designation: "Senior Software Engineer",
                email: "vuhaithuongnute@gmail.com",
                phone: "(522) 396-5660",
              },
            ]
          },
          {
            id: 5,
            name: "Claire Bennett",
            designation: "UI designer",
            email: "manhhachkt08@gmail.com",
            phone: "(529) 277-6234",
            children: [
              {
                id: 6,
                name: "Evan Colon",
                designation: "Technical Specialist",
                email: "ckctm12@gmail.com",
                phone: "(834) 717-1316",
              },
              {
                id: 7,
                name: "Sheri Warner",
                designation: "Business Analyst",
                email: "manhhachkt08@gmail.com",
                phone: "(235) 956-5972",
                children: [
                  {
                    id: 8,
                    name: "Meredith Carlson",
                    designation: "Director Technology",
                    email: "ckctm12@gmail.com",
                    phone: "(240) 251-3383",
                    children: [
                      {
                        id: 9,
                        name: "Daniel Martinez",
                        designation: "UX Engineer",
                        email: "tranthuy.nute@gmail.com",
                        phone: "(702) 210-7352",
                      },
                      {
                        id: 10,
                        name: "Francis Mullins",
                        designation: "Chief Operating Officer",
                        email: "tienlapspktnd@gmail.com",
                        phone: "(343) 468-7012",
                        children: [
                          {
                            id: 11,
                            name: "Deborah Carr",
                            designation: "Project Lead",
                            email: "nvt.isst.nute@gmail.com",
                            phone: "(657) 287-9842",
                          },
                        ],
                      },
                      {
                        id: 12,
                        name: "Minnie Fisher",
                        designation: "Founder",
                        email: "tienlapspktnd@gmail.com",
                        phone: "(468) 955-1223",
                        children: [
                          {
                            id: 13,
                            name: "Billy Harper",
                            designation: "Trainee Engineer",
                            email: "danghoang87hl@gmail.com",
                            phone: "(361) 783-4257",
                          },
                          {
                            id: 14,
                            name: "Anthony Malone",
                            designation: "Chief Executive Officer",
                            email: "tienlapspktnd@gmail.com",
                            phone: "(821) 635-9687",
                            children: [
                              {
                                id: 15,
                                name: "Essie Benson",
                                designation: "Content Writer",
                                email: "tienlapspktnd@gmail.com",
                                phone: "(752) 566-5376",
                              },
                            ],
                          },
                          {
                            id: 16,
                            name: "Lamar Tran",
                            designation: "System Analyst",
                            email: "ckctm12@gmail.com",
                            phone: "(819) 232-6912",
                            children: [
                              {
                                id: 17,
                                name: "Rudolph Stephens",
                                designation: "Manager",
                                email: "tienlapspktnd@gmail.com",
                                phone: "(951) 923-2546",
                              },
                              {
                                id: 18,
                                name: "Matt Parsons",
                                designation: "CEO",
                                email: "thuhang.nute@gmail.com",
                                phone: "(430) 430-3484",
                                children: [
                                  {
                                    id: 19,
                                    name: "Perry Price",
                                    designation: "Delivery Manager",
                                    email: "ckctm12@gmail.com",
                                    phone: "(527) 835-9449",
                                  },
                                ],
                              },
                              {
                                id: 20,
                                name: "Erika Santiago",
                                designation: "Chief Technology Officer",
                                email: "danghoang87hl@gmail.com",
                                phone: "(658) 666-4248",
                              },
                            ]
                          },
                        ]
                      },
                    ]
                  },
                ],
              },
              {
                id: 21,
                name: "Clay Fuller",
                designation: "Process Manager",
                email: "binhan628@gmail.com",
                phone: "(573) 592-1565",
                children: [
                  {
                    id: 22,
                    name: "Mona Fernandez",
                    designation: "Chief Financial Officer",
                    email: "vuhaithuongnute@gmail.com",
                    phone: "(930) 891-6443",
                  },
                  {
                    id: 23,
                    name: "Adam Erickson",
                    designation: "DevOps",
                    email: "binhan628@gmail.com",
                    phone: "(731) 379-9205",
                    children: [
                      {
                        id: 24,
                        name: "Krista Hanson",
                        designation: "Software Engineer",
                        email: "trungkienspktnd@gamail.com",
                        phone: "(326) 429-2204",
                      },
                    ],
                  },
                  {
                    id: 25,
                    name: "Danielle Watkins",
                    designation: "Head of HR",
                    email: "tranthuy.nute@gmail.com",
                    phone: "(989) 813-8178",
                  },
                ]
              },
            ]
          },
        ]
      },
      {
        id: 26,
        name: "Theresa Patrick",
        designation: "Hiring Manager",
        email: "manhhachkt08@gmail.com",
        phone: "(456) 826-2436",
        children: [
          {
            id: 27,
            name: "Audrey Williams",
            designation: "Programmer Analyst",
            email: "thuhang.nute@gmail.com",
            phone: "(831) 522-5847",
          },
          {
            id: 28,
            name: "Monique Hale",
            designation: "HR",
            email: "nvt.isst.nute@gmail.com",
            phone: "(472) 914-5858",
            children: [
              {
                id: 29,
                name: "Alexis Gibson",
                designation: "Director",
                email: "nvt.isst.nute@gmail.com",
                phone: "(581) 314-8180",
              },
            ],
          },
          {
            id: 30,
            name: "Victor Rose",
            designation: "Project Manager",
            email: "ckctm12@gmail.com",
            phone: "(254) 948-2075",
          },
        ]
      },
    ]
  };

  const handleClose = () => {
    setSelected(null);
  };
  const exportTo = () => {
    console.log(filename, fileExtension);
    orgchart.current.exportTo(filename, fileExtension);
  };


  const onNameChange = event => {
    setFilename(event.target.value);
  };

  const onExtensionChange = event => {
    setFileExtension(event);
  };
  return (
    <Card style={{ minHeight: "50vh", overflowX: "scroll", padding: "16px 0 16px" }}>
      <div className="toolbar">
        <label htmlFor="txt-filename">Filename:</label>
        <Input
          id="txt-filename"
          type="text"
          value={filename}
          onChange={onNameChange}
        />
        <Select value={fileExtension} onChange={onExtensionChange}>
          <Option value="pdf">pdf</Option>
          <Option value="png">png</Option>
        </Select>
        <Button type="primary" onClick={exportTo}>
          Export
        </Button>
      </div>
      <OrganizationChart
        ref={orgchart}
        datasource={people}
        chartClass="gcChart"
        NodeTemplate={GcNode}
        onClickNode={(e) => {
          setSelected(e);
        }}
      />
      <Modal
        title="Person Info"
        visible={selected !== null}
        onCancel={handleClose}
        footer={[
          <Button key="back" type="primary" onClick={handleClose}>
            Close
          </Button>,
        ]}
        >
        <Space direction="horizontal" align="center" style={{ marginBottom: 32 }}>
          <div>
            <Avatar src={People1} size={72} />
          </div>
          <div>
            <h2 style={{ marginBottom: 0 }}>{selected?.name}</h2>
            <h3 style={{ marginBottom: 0 }}>{selected?.designation}</h3>
          </div>
        </Space>
        <p><a href={`mailto:${selected?.email}`}><MailOutlined style={{ marginRight: 16 }}/>{selected?.email}</a></p>
        <p><a href={`tel:${selected?.phone}`}><PhoneOutlined style={{ marginRight: 16 }}/>{selected?.phone}</a></p>
      </Modal>
    </Card>
  );
};

export default OrgTree;